<template>
    <div
      class="the-navbar__user-meta flex items-center"
      v-if="activeUserInfo.displayName"
    >
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      </div>

      <div class="relative">
        <div class="con-img ml-3" @click="showDrop">
          <div class="profile-header-image">
            <img
              key="onlineImg"
              :src="activeUserInfo.photoURL"
              alt="user-img"
              class="img-fluid"
            />
          </div>
        </div>
        <div
          class="absolute p-2 t-full border"
          style="
            right: calc(100% - 50px);
            border: solid 1px;
            border-color: #e0e0e0;
            background-color: white;
            top: 120%;
          "
          v-if="shown"
        >
          <div class="arrow-up"></div>
          <ul style="min-width: 9rem">
            <!-- <li
              class="
                flex
                cursor-pointer
                hover:bg-primary hover:text-white
                relative
              "
            >
              <div class="w-full py-2 px-4" @click="profile">
                <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Profile</span>
              </div>
            </li>
            <vs-divider class="m-1" /> -->

            <li
              class="
                flex
                py-2
                px-4
                cursor-pointer
                hover:bg-primary hover:text-white
              "
              @click="logout"
            >
              <feather-icon
                icon="LogOutIcon"
                svgClasses="w-4 h-4"
                @click="logout"
              />
              <span class="ml-2" @click="logout">Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>

  <script>
  import firebase from "firebase/app";
  import "firebase/auth";

  export default {
    data() {
      return {
        shown: false,
      };
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      },
      defaultProfileImage() {
        return this.$store.state.defaultProfileImage;
      },
    },
    methods: {
      logout() {
        // if user is logged in via auth0
        //if (this.$auth.profile) this.$auth.logOut();

        // if user is logged in via firebase
        const firebaseCurrentUser = firebase.auth().currentUser;

        if (firebaseCurrentUser) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              this.$router.push("/login").catch(() => {});
            });
        }

        // If JWT login
        if (
          localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
          )
        ) {
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
          );
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`
          );
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`
          );
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
          );
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
          );
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
          );
          this.$cookies.remove(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
          );
          this.$router.push("/login").catch(() => {});
        }

        sessionStorage.clear();

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change("admin");
        localStorage.removeItem("userInfo");

        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$router.push("/login").catch(() => {});
      },
      profile() {
        this.shown = !this.shown;
        setTimeout(() => {
          this.$store.state.AppActiveUser.userType !== "doctorClinic"
            ? this.$router.push("/clinic/profile")
            : this.$router.push("/doctor-clinic/profile");
        });
      },
      showDrop() {
        this.shown = !this.shown;
      },
    },
  };
  </script>

