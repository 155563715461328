<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div class="relative">
      <div
        class="vx-navbar-wrapper"
        :style="
          $store.state.AppActiveUser.organizations[0].role.toUpperCase() === 'supplier'.toUpperCase() ? '' : 'width: 100%;'
        "
        :class="classObj"
      >
        <vs-navbar
          class="vx-navbar navbar-custom navbar-skelton"
          :color="navbarColorLocal"
          :class="textColor"
        >
          <!-- SM - OPEN SIDEBAR BUTTON -->
          <feather-icon
            class="sm:inline-flex xl:hidden cursor-pointer p-2"
            icon="MenuIcon"
            @click.stop="showSidebar"
          />
  
          <!-- <ClinicSetupBankDetail /> -->
  
          <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->
  
          <vs-spacer />
  
          <!-- <i18n /> -->
  
          <!-- <search-bar /> -->
  
          <!-- <cart-drop-down v-if="showCart" /> -->
          <!--         <ClinicNotificationDropDown />-->
  
          <!-- <clinic-notification-drop-down /> -->
  
          <clinic-profile-drop-down />
        </vs-navbar>
      </div>
    </div>
  </template>
  
  <script>
  // import Bookmarks from "../components/Bookmarks.vue";
  // import I18n from "../components/I18n.vue";
  // import SearchBar from "../components/SearchBar.vue";
//   import CartDropDown from "../components/clinic/ClinicCartDropDown.vue";
  // import ClinicNotificationDropDown from "../components/clinic/ClinicNotificationDropDown.vue";
   import ClinicProfileDropDown from "../components/supplier/SupplierProfileDropDown.vue";
  // import ClinicProfileDropDown from "../components/ProfileDropDown.vue";
//   import ClinicSetupBankDetail from "../components/basic/BasicSetupBankDetail.vue";
//   import ClinicNotificationDropDown from "../components/clinic/ClinicNotificationDropDown";
  import LDClient from "launchdarkly-js-client-sdk";
  export default {
    name: "TheClinicNavbarVertical",
    props: {
      navbarColor: {
        type: String,
        default: "#fff",
      },
    },
    data: () => ({
      clinicCompanyName: "",
      showCart: true,
    }),
    components: {
    //   ClinicNotificationDropDown,
      // Bookmarks,
      // I18n,
      // SearchBar,
    //   CartDropDown,
      // ClinicNotificationDropDown,
      ClinicProfileDropDown,
    //   ClinicSetupBankDetail,
    },
    computed: {
      navbarColorLocal() {
        return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
          ? "#10163a"
          : this.navbarColor;
      },
      verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth;
      },
      textColor() {
        return {
          "text-white":
            (this.navbarColor != "#10163a" &&
              this.$store.state.theme === "dark") ||
            (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
        };
      },
      windowWidth() {
        return this.$store.state.windowWidth;
      },
  
      // NAVBAR STYLE
      // eslint-disable-next-line vue/return-in-computed-property
      classObj() {
        if (this.verticalNavMenuWidth == "default") return "navbar-default";
        else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
        else if (this.verticalNavMenuWidth) return "navbar-full";
      },
    },
    beforeMount: function () {
      const { badge: key, email } = this.$store.state.AppActiveUser;
      var user = {
        key: key,
        email: email,
      };
      this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
      this.ldClient.on("ready", this.onLaunchDarklyReady);
      this.ldClient.on("change", this.onLaunchDarklyReady);
    },
    methods: {
      onLaunchDarklyReady() {
        this.showCart = this.ldClient.variation("cart", false);
      },
      showSidebar() {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      },
    },
    watch: {
      "this.$store.state.AppActiveUser.clinicName": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.clinicCompanyName = this.$store.state.AppActiveUser.clinicName;
        }
      },
    },
    created() {
      this.clinicCompanyName = this.$store.state.AppActiveUser.clinicName;
    },
  };
  </script>
  